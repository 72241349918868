import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { WindowWidth, formatDate } from "utils";

import { Button, Typography } from "components/Layout";
import theme from "theme";

import { useImportShows } from "components/Shows";

const today = new Date();

function ShowText({ text, background, type = "title", ...props }) {
  return (
    <Typography
      type={type}
      variant={type === "subtitle" ? "body1" : "h6"}
      background={background}
      {...props}
    >
      {text}
    </Typography>
  );
}

function ShowsRow({ show, background, index, upcoming = false }) {
  let breakPoint = WindowWidth().breakPoint;

  return (
    <Grid
      item
      xs={12}
      key={index}
      sx={{
        borderBottom: `2px solid ${
          background === "white"
            ? theme.palette.primary.main
            : theme.palette.secondary.main
        }`,
        color: theme.palette.text.secondary,
      }}
    >
      <Grid container columnSpacing={2} alignItems="center" mb={2}>
        <Grid item sx={{ width: breakPoint === "sm" ? "100%" : 150 }}>
          <ShowText text={formatDate(show.date)} background={background} />
        </Grid>

        <Grid
          item
          xs
          sx={{
            textAlign: "left",
          }}
        >
          {["ie", "de", "uk"].includes(show.country.toLowerCase()) && (
            <img
              width="30"
              height="30"
              style={{
                verticalAlign: "middle",
                marginRight: 10,
                marginTop: -5,
              }}
              alt={`country_${show.country}`}
              src={`/assets/img/countryflags/${show.country.toLowerCase()}.svg`}
            />
          )}
          <ShowText text={show.name} background={background} component="span" />
          <ShowText
            text={show.location}
            type="subtitle"
            background={background}
          />
        </Grid>

        {upcoming && show.url && show.url.includes("https://") && (
          <Grid item>
            {
              <Button
                size="small"
                color="secondary"
                section="dark"
                href={show.url}
              >
                More info
              </Button>
            }
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default function ShowsList({
  upcoming,
  past,
  maxShowsInView,
  background,
}) {
  const shows = useImportShows().sort();

  var date = new Date();
  // remove 1 day, counters for 'today'
  date.setDate(date.getDate() - 1);

  const upcomingShows = shows
    .filter((show) => {
      const showDate = new Date(show.date);
      if (showDate.getTime() >= date.getTime()) {
        return show;
      }
    })
    .sort((showA, showB) => {
      const showDateA = new Date(showA.date);
      const showDateB = new Date(showB.date);
      return showDateA.getTime() > showDateB.getTime() ? -1 : 1;
    })
    .reverse();

  const pastShows = shows
    .filter((show) => {
      const showDate = new Date(show.date);
      if (showDate.getTime() < date.getTime()) {
        return show;
      }
    })
    .sort((showA, showB) => {
      const showDateA = new Date(showA.date);
      const showDateB = new Date(showB.date);
      return showDateA.getTime() > showDateB.getTime() ? -1 : 1;
    })
    .filter((show, index) => index <= maxShowsInView);

  return (
    <Grid container rowSpacing={2}>
      {upcoming && upcomingShows.length === 0 && (
        <Grid
          item
          xs={12}
          sx={{
            color: (theme) =>
              background === "white"
                ? theme.palette.secondary.contrastText
                : theme.palette.primary.contrastText,
            textAlign: "center",
          }}
          pb={4}
        >
          <Typography
            variant="body1"
            background={background}
            sx={{ fontStyle: "italic" }}
          >
            There are no upcoming shows at the moment.
          </Typography>
        </Grid>
      )}
      {upcoming &&
        upcomingShows.map((show, index) => {
          return (
            <ShowsRow
              show={show}
              background={background}
              index={index}
              key={index}
              upcoming
            />
          );
        })}
      {past &&
        pastShows.map((show, index) => {
          return (
            <ShowsRow
              show={show}
              background={background}
              index={index}
              key={index}
            />
          );
        })}
    </Grid>
  );
}
