import React, { useState } from "react";

import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
  Grid,
  Button,
  Typography,
  SpotifyIcon,
  SoundCloudIcon,
  BandCampIcon,
  AppleIcon,
  YoutubeIcon,
  TriskelIcon,
} from "components/Layout";

import theme from "theme";

export default function AlbumButtons({ listen = {}, buy = {} }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [topic, setTopic] = useState(null);

  const closePopper = () => {
    setAnchorEl(null);
    setTopic(null);
  };

  const getIcon = (link) => {
    switch (link) {
      case "spotify":
        return <SpotifyIcon color="#ffffff" />;
      case "bandcamp":
        return <BandCampIcon color="#ffffff" />;
      case "soundcloud":
        return <SoundCloudIcon color="#ffffff" />;
      case "youtube":
        return <YoutubeIcon color="#ffffff" />;
      case "apple":
        return <AppleIcon color="#ffffff" />;
      case "celtic_world":
        return <TriskelIcon color="#ffffff" />;
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs="auto" sx={{ textAlign: "center" }}>
          {Object.keys(listen).length > 0 && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{ margin: theme.spacing(2) }}
              onClick={(e) => {
                setTopic("listen");
                setAnchorEl(e.currentTarget);
              }}
            >
              Listen
            </Button>
          )}
          {Object.keys(buy).length > 0 && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{ margin: theme.spacing(2) }}
              onClick={(e) => {
                setTopic("buy");
                setAnchorEl(e.currentTarget);
              }}
            >
              Buy
            </Button>
          )}
        </Grid>
      </Grid>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => closePopper()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          marginTop: 1,
        }}
      >
        {topic === "listen" && (
          <List>
            {Object.keys(listen).map((item, index) => {
              return (
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    href={listen[item]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemIcon>{getIcon(item)}</ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
        {topic === "buy" && (
          <List>
            {Object.keys(buy).map((item, index) => {
              return (
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    href={listen[item]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemIcon>{getIcon(item)}</ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Popover>
    </>
  );
}
