import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { WindowWidth } from "utils";

import VideoReel from "./VideoReel";

import {
  PageHeader,
  Section,
  Grid,
  Link,
  HeaderFilter,
  Typography,
} from "components/Layout";

import { photoGallery, authors } from "./constants";

const filters = ["photos", "videos"];

function GalleryPreview({ title, image, to, author }) {
  const navigate = useNavigate();

  let breakPoint = WindowWidth().breakPoint;

  return (
    <Grid container mb={2}>
      <Grid
        item
        onClick={() => {
          navigate(to, {});
        }}
        sx={{
          cursor: "pointer",
          position: "relative",
        }}
      >
        <Typography
          variant={["sm", "md"].includes(breakPoint) ? "h6" : "h4"}
          background="black"
          family="rubikDirt"
          typographySx={{
            position: "absolute",
            top: 20,
            left: 20,
          }}
        >
          {title}
        </Typography>
        <img
          src={image}
          style={{
            maxWidth: "100%",
          }}
        />
      </Grid>
      <Grid item>
        <Link href={authors[author].url} variant="body2">
          photo's by {authors[author].name}
        </Link>
      </Grid>
    </Grid>
  );
}

export default function MediaListPage() {
  let { type } = useParams();

  const [filter, setFilter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setFilter(filters.indexOf(type));
  }, [type]);

  return (
    <>
      <PageHeader url="header_band.jpg" position="top center" />

      <Section type="white">
        <Grid container justifyContent="center" rowSpacing={0}>
          <Grid item xs={12} mb={4}>
            <HeaderFilter
              centered
              selected={filter}
              setSelected={(e) => {
                setFilter(e);
                navigate(`/media/${filters[e]}`);
              }}
              tabs={filters}
              background="white"
            />
          </Grid>
        </Grid>

        {filter === 0 && (
          <Grid container spacing={2}>
            {photoGallery.map((gallery, index) => {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <GalleryPreview {...gallery} />
                </Grid>
              );
            })}
          </Grid>
        )}
        {filter === 1 && (
          <Grid item xs={12}>
            <VideoReel
              videos={[
                "ezM-2KETqds",
                "SfDwmdoEnYw",
                "AL3XYbvJttw",
                "m4NdZajwRoU",
                "yLS6qmuwdWg",
                "tpG-dQYQ1wc",
              ]}
            />
          </Grid>
        )}
      </Section>
    </>
  );
}
