import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "components/Layout";

import { WindowWidth } from "utils";

export default function Section({
  children,
  background,
  title,
  noBackground,
  noPadding,
  sizeProps = { xs: 12 },
  ...props
}) {
  let breakPoint = WindowWidth().breakPoint;

  function makeBackgroundImage(background) {
    switch (background) {
      case "green":
        return 'linear-gradient(rgba(13,129,60,.5), rgba(13,129,60,.5)), url("/assets/img/backgrounds/background_green.jpg")';
      case "white":
        return 'linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("/assets/img/backgrounds/background_white.jpg")';
      case "black":
        return 'linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("/assets/img/backgrounds/background_black.jpg")';
      default:
        return null;
    }
  }

  function makeBackgroundColor(background, theme) {
    switch (background) {
      case "black":
        return theme.palette.primary.main;
      case "green":
        return theme.palette.tertiary.main;
      default:
        return theme.palette.secondary.main;
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      wrap="wrap"
      sx={{
        backgroundColor: (theme) =>
          !noBackground
            ? makeBackgroundColor(background, theme)
            : "transparent",
        backgroundImage: (theme) =>
          makeBackgroundImage(!noBackground ? background : null),
      }}
    >
      {title && (
        <Grid
          item
          xs={12}
          sx={{
            padding: (theme) => theme.spacing(6, 4, 2, 4),
            textAlign: "center",
          }}
        >
          <Typography
            variant={["sm", "md"].includes(breakPoint) ? "h3" : "h2"}
            family="rubikDirt"
            background={background}
          >
            {title}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        {...sizeProps}
        sx={{
          padding: (theme) => (noPadding ? 0 : theme.spacing(4)),
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}
