import React, { useRef, useEffect, useState } from "react";

import ReactPlayer from "react-player/youtube";

import Typography from "./Typography";

export default function VideoBoxYoutube({ id, ...props }) {
  const ratio = 360 / 640;
  const ref = useRef(null);
  const [videoHeight, setVideoHeight] = useState(0);

  useEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0;
    const height = Math.floor(width * ratio);
    setVideoHeight(height);
  }, [ref.current]);

  return (
    <div ref={ref}>
      <ReactPlayer
        url={`https://www.youtube.com/watch?v=${id}`}
        width="100%"
        height={videoHeight}
      />
    </div>
  );
}
