import React from "react";
import MuiIconButton from "@mui/material/IconButton";

export default function IconButton({ children, href, ...props }) {
  const buttonProps = {
    ...props,
    ...(href && {
      href,
      target: "_blank",
      rel: "noopener",
    }),
  };

  return <MuiIconButton {...buttonProps}>{children}</MuiIconButton>;
}
