import React from "react";
import IconButton from "./IconButton";
import Box from "@mui/material/Box";
//import { ReactComponent as Facebook } from './facebook.svg'
import {
  FaceBookIcon,
  InstagramIcon,
  YoutubeIcon,
  SpotifyIcon,
  SoundCloudIcon,
} from "./utils";

export default function socials({ size = 40, color = "#ffffff", ...props }) {
  return (
    <>
      <IconButton href="https://www.facebook.com/royalspuds/">
        <FaceBookIcon sx={{ fontSize: size }} color={color} />
      </IconButton>
      <IconButton href="https://www.instagram.com/theroyalspuds/">
        <InstagramIcon sx={{ fontSize: size }} color={color} />
      </IconButton>
      <IconButton href="https://www.youtube.com/@theroyalspuds">
        <YoutubeIcon sx={{ fontSize: size }} color={color} />
      </IconButton>
      <IconButton href="https://open.spotify.com/artist/1HaBf4P6nvngzIKJh6jJ22">
        <SpotifyIcon sx={{ fontSize: size }} color={color} />
      </IconButton>
      <IconButton href="https://soundcloud.com/royalspuds">
        <SoundCloudIcon sx={{ fontSize: size }} color={color} />
      </IconButton>
    </>
  );
}
