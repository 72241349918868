import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { HomePage } from "components/Home";
import { ShowsPage } from "components/Shows";
import { BandPage } from "components/Band";
import { ShopPage } from "components/Shop";
import { MediaListPage, MediaDetailPage } from "components/Media";
import { BookingPage } from "components/Booking";
import { DiscographyPage } from "components/Discography";
import { WindowWidth } from "utils";

export default function Content() {
  let location = useLocation();

  let breakPoint = WindowWidth().breakPoint;
  let isMobile = ["sm", "md"].includes(breakPoint);

  const [lastUrl, setLastUrl] = useState("/");

  useEffect(() => {
    const baseUrl = location.pathname.split("/")[1];
    //prevent scrollTop if tab is changed
    if (lastUrl !== baseUrl) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
      setLastUrl(baseUrl);
    }
  }, [location, window]);

  return (
    <Routes>
      <Route path="shows" element={<ShowsPage />} />
      <Route path="band" element={<BandPage />} />
      <Route path="shop" element={<ShopPage />} />
      <Route path="discography/:album" element={<DiscographyPage />} />
      <Route path="media/:type/:collection" element={<MediaDetailPage />} />
      <Route path="media/:type" element={<MediaListPage />} />
      <Route path="booking" element={<BookingPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
}
