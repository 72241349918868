import { useState, useEffect } from "react";
import {
  breakpointLg,
  breakpointMd,
  breakpointSm,
} from "components/App/constants";

export function WindowWidth() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    breakPoint:
      window.innerWidth <= breakpointSm
        ? "sm"
        : window.innerWidth < breakpointMd
        ? "md"
        : window.innerWidth < breakpointLg
        ? "lg"
        : "xl",
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        breakPoint:
          window.innerWidth <= breakpointSm
            ? "sm"
            : window.innerWidth < breakpointMd
            ? "md"
            : window.innerWidth < breakpointLg
            ? "lg"
            : "xl",
      });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window]);

  return windowSize;
}

export function WindowScroll() {
  const [windowPosition, setWindowPosition] = useState({
    scrollTop: 0,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowPosition({
        scrollTop:
          document.documentElement.scrollTop || document.body.scrollTop,
      });
    };

    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, [window]);

  return windowPosition;
}

export function createAltText(text) {
  try {
    const splitImage = text.split(".");
    return splitImage[0].replaceAll("_", " ");
  } catch (e) {
    return "";
  }
}

export function formatDate(date) {
  try {
    const splitDate = date.split("-");
    splitDate.reverse();
    return splitDate.join("-");
  } catch (e) {}
}
