import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function FaceBookIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M13.301,6.824c0-0.76,0.68-1.034,1.44-1.034c0.762,0,1.573,0.236,1.573,0.236l0.487-2.896
	c0,0-1.034-0.354-3.501-0.354c-1.515,0-2.394,0.577-3.036,1.426C9.66,5.007,9.636,6.301,9.636,7.136v1.896H7.68v2.831h1.957v9.825
	h3.665v-9.825h2.902l0.216-2.831h-3.118V6.824z"
      />
    </SvgIcon>
  );
}

export function InstagramIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M21.743,7.686c-0.112-1.144-0.326-1.902-0.722-2.53c-0.43-0.684-1.02-1.275-1.705-1.706
		c-0.627-0.394-1.385-0.61-2.528-0.72C15.651,2.62,14.22,2.62,12.241,2.62c-1.978,0-3.408,0-4.544,0.111
		c-1.146,0.11-1.902,0.326-2.529,0.72C4.479,3.881,3.89,4.472,3.46,5.156c-0.395,0.627-0.609,1.386-0.722,2.53
		c-0.109,1.138-0.109,2.568-0.109,4.545c0,1.98,0,3.41,0.109,4.547c0.113,1.145,0.327,1.899,0.722,2.53
		c0.43,0.684,1.021,1.273,1.708,1.705c0.627,0.393,1.384,0.61,2.528,0.721c1.138,0.11,2.568,0.11,4.546,0.11
		c1.979,0,3.41,0,4.547-0.11c1.144-0.11,1.901-0.328,2.528-0.723c0.686-0.43,1.275-1.02,1.705-1.703
		c0.396-0.629,0.609-1.384,0.722-2.53c0.109-1.138,0.109-2.567,0.109-4.547C21.853,10.254,21.853,8.824,21.743,7.686z
		 M12.241,21.055c-1.956,0-3.37,0-4.47-0.105c-1.014-0.1-1.668-0.279-2.185-0.604c-0.585-0.367-1.089-0.873-1.457-1.458
		c-0.327-0.518-0.508-1.172-0.604-2.187c-0.107-1.1-0.107-2.513-0.107-4.47c0-1.955,0-3.369,0.107-4.469
		C3.622,6.749,3.803,6.094,4.13,5.579c0.367-0.586,0.872-1.091,1.457-1.459c0.517-0.323,1.171-0.504,2.185-0.604
		c1.1-0.106,2.514-0.106,4.47-0.106c1.957,0,3.37,0,4.471,0.106c1.014,0.099,1.668,0.28,2.185,0.604
		c0.587,0.369,1.089,0.873,1.458,1.459c0.325,0.516,0.505,1.17,0.603,2.183c0.107,1.101,0.107,2.513,0.107,4.469
		c0,1.958,0,3.371-0.107,4.47c-0.098,1.015-0.277,1.669-0.603,2.187c-0.369,0.585-0.871,1.091-1.458,1.458
		c-0.517,0.324-1.171,0.504-2.185,0.604C15.611,21.055,14.198,21.055,12.241,21.055z"
      />
      <path
        fill={color}
        d="M12.241,7.648c-2.528,0-4.585,2.056-4.585,4.583c0,2.528,2.057,4.584,4.585,4.584s4.584-2.057,4.584-4.584
		C16.825,9.704,14.77,7.648,12.241,7.648z M12.241,16.026c-2.092,0-3.795-1.701-3.795-3.795c0-2.092,1.704-3.794,3.795-3.794
		s3.795,1.702,3.795,3.794C16.036,14.325,14.333,16.026,12.241,16.026z"
      />
      <path
        fill={color}
        d="M17.689,5.528c-0.695,0-1.258,0.563-1.258,1.256s0.563,1.258,1.258,1.258c0.691,0,1.255-0.564,1.255-1.258
		S18.381,5.528,17.689,5.528z"
      />
    </SvgIcon>
  );
}

export function SpotifyIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M17.826,11.192c-3.027-1.799-8.092-1.988-10.978-1.09c-0.473,0.144-0.946-0.143-1.09-0.567
		C5.617,9.061,5.902,8.588,6.328,8.446c3.359-0.994,8.895-0.804,12.399,1.279c0.424,0.235,0.566,0.804,0.33,1.229
		C18.819,11.285,18.253,11.427,17.826,11.192 M17.73,13.842c-0.236,0.33-0.662,0.473-0.993,0.238
		c-2.555-1.563-6.437-2.037-9.417-1.092c-0.378,0.096-0.806-0.093-0.9-0.471c-0.093-0.378,0.094-0.805,0.476-0.899
		c3.452-1.042,7.711-0.52,10.646,1.277C17.826,13.037,17.969,13.51,17.73,13.842 M16.596,16.443
		c-0.189,0.285-0.522,0.379-0.805,0.189c-2.223-1.371-5.017-1.654-8.329-0.9c-0.331,0.098-0.614-0.141-0.709-0.424
		c-0.096-0.33,0.144-0.617,0.425-0.709c3.597-0.807,6.721-0.475,9.183,1.039C16.69,15.781,16.737,16.158,16.596,16.443
		 M12.242,2.767c-5.205,0-9.464,4.258-9.464,9.464c0,5.208,4.259,9.464,9.464,9.464s9.463-4.256,9.463-9.464
		C21.705,7.025,17.494,2.767,12.242,2.767"
      />
    </SvgIcon>
  );
}

export function SoundCloudIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M1.082,16.256c0,0.281,0.102,0.492,0.302,0.638c0.202,0.142,0.415,0.194,0.643,0.151
		c0.216-0.043,0.367-0.12,0.452-0.231c0.087-0.112,0.129-0.298,0.129-0.558v-3.054c0-0.22-0.072-0.404-0.223-0.554
		c-0.148-0.151-0.33-0.227-0.546-0.227c-0.205,0-0.384,0.076-0.533,0.227c-0.15,0.15-0.225,0.334-0.225,0.554V16.256L1.082,16.256z
		 M3.471,17.563c0,0.203,0.071,0.354,0.213,0.456c0.142,0.103,0.323,0.153,0.545,0.153c0.229,0,0.415-0.051,0.557-0.153
		c0.141-0.103,0.213-0.253,0.213-0.456v-7.119c0-0.213-0.074-0.393-0.224-0.544C4.625,9.75,4.444,9.674,4.229,9.674
		c-0.206,0-0.386,0.076-0.534,0.226c-0.149,0.151-0.224,0.331-0.224,0.544V17.563L3.471,17.563z M5.85,17.897
		c0,0.203,0.072,0.356,0.217,0.459c0.145,0.101,0.332,0.152,0.561,0.152c0.222,0,0.404-0.052,0.546-0.152
		c0.14-0.103,0.212-0.256,0.212-0.459V11.4c0-0.217-0.074-0.403-0.224-0.558c-0.148-0.155-0.327-0.232-0.535-0.232
		c-0.216,0-0.397,0.077-0.551,0.232C5.925,10.997,5.85,11.183,5.85,11.4V17.897L5.85,17.897z M8.236,17.931
		c0,0.385,0.257,0.578,0.77,0.578c0.514,0,0.769-0.193,0.769-0.578V7.399c0-0.591-0.177-0.924-0.529-1.002
		C9.017,6.342,8.791,6.41,8.57,6.597C8.349,6.788,8.236,7.054,8.236,7.399V17.931L8.236,17.931z M10.668,18.234V6.777
		c0-0.364,0.107-0.583,0.32-0.653c0.464-0.113,0.925-0.168,1.382-0.168c1.06,0,2.046,0.253,2.961,0.758
		c0.912,0.506,1.651,1.195,2.216,2.072c0.564,0.873,0.893,1.836,0.982,2.889c0.422-0.183,0.871-0.274,1.349-0.274
		c0.97,0,1.799,0.349,2.488,1.043c0.688,0.693,1.034,1.529,1.034,2.506c0,0.982-0.346,1.823-1.034,2.517
		c-0.689,0.695-1.515,1.043-2.479,1.043l-9.034-0.011c-0.063-0.021-0.108-0.06-0.138-0.115
		C10.682,18.326,10.668,18.275,10.668,18.234L10.668,18.234L10.668,18.234z"
      />
    </SvgIcon>
  );
}

export function YoutubeIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M21.896,7.338c-0.112-0.431-0.336-0.826-0.651-1.142c-0.312-0.316-0.706-0.545-1.136-0.659
	c-2.61-0.307-5.238-0.448-7.87-0.414C9.613,5.09,6.983,5.23,4.373,5.538C3.942,5.651,3.552,5.88,3.237,6.196
	c-0.313,0.316-0.538,0.711-0.65,1.142C2.3,8.943,2.16,10.568,2.171,12.196c-0.011,1.638,0.128,3.274,0.416,4.884
	c0.117,0.424,0.344,0.811,0.657,1.122c0.314,0.308,0.703,0.529,1.129,0.642c2.61,0.307,5.24,0.445,7.867,0.416
	c2.631,0.029,5.259-0.109,7.87-0.416c0.426-0.112,0.814-0.334,1.129-0.642c0.313-0.312,0.541-0.698,0.658-1.122
	c0.285-1.609,0.426-3.246,0.414-4.884C22.322,10.568,22.182,8.943,21.896,7.338z M10.176,15.205V9.214l5.276,2.982l-5.276,2.997
	V15.205z"
      />
    </SvgIcon>
  );
}

export function BandCampIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#F7F7F7"
        d="M1.974,18.143h13.772l6.341-11.914H8.315L1.974,18.143z"
      />
    </SvgIcon>
  );
}

export function AppleIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M15.657,2.729c0.046,0,0.088,0,0.137,0c0.108,1.34-0.404,2.342-1.026,3.067
					c-0.608,0.719-1.444,1.418-2.796,1.312c-0.09-1.321,0.422-2.248,1.043-2.972C13.591,3.462,14.646,2.862,15.657,2.729z"
      />
      <path
        fill={color}
        d="M19.747,16.679c0,0.015,0,0.026,0,0.038c-0.379,1.151-0.921,2.136-1.582,3.051
        c-0.603,0.831-1.344,1.948-2.663,1.948c-1.141,0-1.897-0.733-3.068-0.754c-1.236-0.02-1.917,0.613-3.047,0.773
        c-0.129,0-0.259,0-0.386,0c-0.83-0.12-1.5-0.779-1.988-1.37c-1.44-1.752-2.552-4.014-2.759-6.908c0-0.284,0-0.566,0-0.851
        c0.088-2.071,1.094-3.755,2.432-4.571c0.706-0.434,1.676-0.804,2.758-0.639c0.462,0.072,0.936,0.231,1.351,0.388
        c0.393,0.151,0.884,0.418,1.351,0.404c0.315-0.009,0.629-0.174,0.946-0.29c0.932-0.337,1.846-0.723,3.048-0.541
        c1.446,0.219,2.475,0.861,3.108,1.852c-1.225,0.779-2.192,1.952-2.025,3.957C17.368,14.987,18.426,16.053,19.747,16.679z"
      />
    </SvgIcon>
  );
}

export function TriskelIcon({ color = "#F7F7F7", ...props }) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M17.642,16.554c-0.039,0.002-0.086-0.008-0.12-0.025c-0.036-0.018-0.074-0.053-0.098-0.086
			c-0.027-0.038-0.047-0.089-0.057-0.133c-0.008-0.054-0.005-0.115,0.008-0.166c0.014-0.059,0.044-0.122,0.077-0.171
			c0.039-0.056,0.096-0.111,0.15-0.151c0.062-0.044,0.144-0.083,0.217-0.104c0.081-0.024,0.18-0.037,0.266-0.035
			c0.095,0.004,0.203,0.024,0.291,0.054c0.099,0.035,0.205,0.093,0.29,0.153c0.092,0.066,0.186,0.159,0.253,0.25
			c0.075,0.099,0.144,0.225,0.187,0.337c0.046,0.126,0.079,0.275,0.09,0.409c0.011,0.143,0,0.307-0.03,0.446
			c-0.029,0.148-0.092,0.313-0.161,0.45c-0.076,0.144-0.187,0.295-0.298,0.412c-0.118,0.126-0.275,0.25-0.424,0.336
			c-0.155,0.093-0.351,0.175-0.526,0.222c-0.186,0.05-0.406,0.076-0.598,0.074c-0.202-0.002-0.434-0.037-0.626-0.093
			c-0.205-0.061-0.429-0.16-0.609-0.271c-0.188-0.117-0.387-0.281-0.537-0.443c-0.158-0.171-0.312-0.391-0.419-0.597
			c-0.11-0.214-0.205-0.479-0.255-0.715c-0.054-0.247-0.075-0.537-0.061-0.788c0.017-0.262,0.074-0.56,0.157-0.808
			c0.087-0.258,0.228-0.54,0.38-0.766c0.156-0.233,0.375-0.478,0.588-0.662c0.222-0.19,0.505-0.375,0.769-0.5
			c0.272-0.13,0.606-0.237,0.904-0.291c0.307-0.056,0.668-0.072,0.979-0.045c0.32,0.028,0.685,0.109,0.987,0.222
			c0.313,0.115,0.652,0.295,0.924,0.487c0.278,0.198,0.568,0.47,0.787,0.734c0.223,0.272,0.438,0.62,0.582,0.94
			c0.149,0.332,0.269,0.734,0.325,1.093c0.059,0.368,0.069,0.799,0.029,1.171c-0.04,0.38-0.146,0.812-0.284,1.168
			c-0.143,0.365-0.362,0.765-0.597,1.081c-0.238,0.324-0.563,0.659-0.878,0.911c-0.325,0.256-0.736,0.501-1.116,0.665
			c-0.388,0.167-0.86,0.299-1.279,0.359c-0.429,0.063-0.932,0.067-1.361,0.016c-0.44-0.055-0.938-0.184-1.349-0.351
			c-0.422-0.169-0.878-0.429-1.239-0.703c-0.37-0.28-0.751-0.658-1.035-1.025c-0.29-0.375-0.565-0.85-0.748-1.287
			c-0.186-0.446-0.332-0.989-0.395-1.469c-0.059-0.398-0.187-0.848-0.35-1.216c-0.156-0.36-0.392-0.751-0.638-1.057
			c-0.239-0.299-0.559-0.605-0.868-0.832c-0.301-0.22-0.68-0.426-1.028-0.56c-0.339-0.13-0.748-0.227-1.108-0.263
			c-0.351-0.036-0.759-0.023-1.107,0.033c-0.337,0.056-0.717,0.171-1.028,0.313c-0.303,0.14-0.628,0.344-0.884,0.557
			c-0.247,0.205-0.5,0.48-0.686,0.744c-0.178,0.257-0.345,0.577-0.452,0.87c-0.101,0.284-0.176,0.628-0.2,0.929
			c-0.023,0.291-0.005,0.629,0.05,0.916c0.052,0.276,0.155,0.588,0.279,0.841c0.12,0.244,0.293,0.507,0.473,0.712
			c0.173,0.195,0.402,0.396,0.621,0.539c0.211,0.138,0.474,0.265,0.713,0.344c0.23,0.074,0.506,0.125,0.747,0.136
			c0.231,0.01,0.499-0.013,0.726-0.064c0.215-0.05,0.457-0.14,0.652-0.244c0.187-0.102,0.385-0.244,0.539-0.391
			c0.145-0.141,0.292-0.324,0.394-0.497c0.097-0.165,0.186-0.369,0.235-0.555c0.047-0.176,0.074-0.386,0.072-0.567
			c-0.001-0.172-0.031-0.37-0.08-0.533c-0.045-0.156-0.122-0.328-0.209-0.465c-0.082-0.128-0.195-0.264-0.309-0.366
			c-0.106-0.094-0.243-0.188-0.372-0.249c-0.119-0.057-0.266-0.104-0.397-0.126c-0.122-0.02-0.264-0.023-0.386-0.008
			c-0.113,0.014-0.24,0.049-0.343,0.094c-0.096,0.043-0.198,0.106-0.276,0.174c-0.071,0.063-0.143,0.146-0.193,0.228
			c-0.044,0.072-0.083,0.164-0.103,0.247c-0.018,0.074-0.025,0.163-0.018,0.24c0.006,0.066,0.026,0.145,0.055,0.206
			c0.026,0.054,0.066,0.111,0.11,0.151c0.038,0.037,0.09,0.07,0.139,0.089c0.044,0.016,0.098,0.023,0.144,0.02
			c0.042-0.004,0.089-0.019,0.123-0.043c0.032-0.021,0.064-0.056,0.082-0.091c0.018-0.035,0.049-0.069,0.082-0.092
			c0.035-0.021,0.083-0.037,0.124-0.042c0.046-0.005,0.1,0.005,0.144,0.021c0.049,0.019,0.102,0.052,0.14,0.088
			c0.044,0.041,0.084,0.098,0.11,0.151c0.029,0.063,0.049,0.139,0.055,0.207c0.008,0.076,0,0.164-0.018,0.239
			c-0.02,0.083-0.059,0.174-0.104,0.247c-0.049,0.081-0.121,0.165-0.192,0.227c-0.079,0.068-0.181,0.132-0.276,0.175
			c-0.104,0.045-0.231,0.08-0.343,0.095c-0.122,0.015-0.265,0.012-0.386-0.008c-0.131-0.022-0.278-0.07-0.398-0.127
			c-0.128-0.062-0.266-0.153-0.372-0.249c-0.114-0.101-0.228-0.236-0.309-0.365c-0.086-0.138-0.163-0.31-0.208-0.464
			c-0.049-0.166-0.078-0.363-0.08-0.534c-0.002-0.183,0.024-0.392,0.072-0.567c0.05-0.185,0.137-0.39,0.234-0.556
			c0.103-0.173,0.249-0.356,0.395-0.495c0.153-0.147,0.353-0.291,0.538-0.392c0.195-0.105,0.437-0.195,0.653-0.244
			c0.226-0.052,0.494-0.074,0.725-0.064c0.242,0.011,0.517,0.062,0.747,0.137c0.24,0.076,0.502,0.204,0.713,0.343
			c0.219,0.143,0.447,0.343,0.621,0.54c0.179,0.203,0.354,0.466,0.474,0.711c0.124,0.254,0.226,0.563,0.279,0.84
			c0.055,0.287,0.072,0.624,0.049,0.917c-0.023,0.302-0.098,0.643-0.2,0.927c-0.105,0.294-0.272,0.614-0.451,0.871
			c-0.184,0.264-0.438,0.538-0.685,0.745c-0.255,0.212-0.583,0.418-0.884,0.556c-0.311,0.142-0.691,0.258-1.029,0.313
			c-0.348,0.058-0.756,0.07-1.106,0.034c-0.361-0.036-0.77-0.135-1.108-0.264c-0.348-0.133-0.728-0.341-1.029-0.56
			c-0.309-0.226-0.63-0.533-0.87-0.83C2.759,19.34,2.523,18.95,2.367,18.59c-0.162-0.37-0.29-0.818-0.349-1.218
			c-0.061-0.408-0.067-0.887-0.019-1.297c0.049-0.421,0.171-0.896,0.328-1.29c0.161-0.401,0.408-0.839,0.668-1.186
			c0.266-0.354,0.627-0.721,0.977-0.993c0.358-0.279,0.812-0.544,1.229-0.721c0.427-0.18,0.946-0.321,1.406-0.384
			c0.469-0.063,1.018-0.065,1.488-0.004c0.48,0.062,1.021,0.207,1.469,0.392c0.375,0.148,0.828,0.262,1.229,0.307
			c0.39,0.043,0.845,0.035,1.234-0.024c0.379-0.058,0.804-0.182,1.154-0.337c0.34-0.151,0.709-0.376,0.999-0.61
			c0.28-0.229,0.569-0.534,0.782-0.828c0.206-0.286,0.4-0.646,0.524-0.976c0.119-0.32,0.21-0.707,0.241-1.048
			c0.031-0.331,0.018-0.716-0.039-1.043c-0.056-0.318-0.166-0.675-0.303-0.966c-0.132-0.282-0.326-0.587-0.527-0.826
			c-0.195-0.23-0.455-0.466-0.704-0.637c-0.24-0.165-0.543-0.319-0.816-0.415c-0.268-0.093-0.587-0.159-0.87-0.179
			c-0.271-0.019-0.584,0.001-0.852,0.055c-0.257,0.051-0.544,0.15-0.778,0.268c-0.226,0.112-0.467,0.277-0.654,0.446
			c-0.179,0.162-0.361,0.375-0.491,0.579C9.57,5.847,9.456,6.091,9.387,6.312C9.322,6.524,9.279,6.778,9.272,6.999
			c-0.005,0.212,0.019,0.457,0.07,0.662C9.39,7.857,9.476,8.075,9.574,8.25c0.094,0.167,0.228,0.345,0.363,0.481
			c0.128,0.128,0.296,0.257,0.455,0.346c0.15,0.084,0.336,0.158,0.503,0.198c0.158,0.039,0.345,0.058,0.507,0.051
			c0.152-0.006,0.326-0.037,0.47-0.084c0.136-0.045,0.284-0.118,0.402-0.198c0.109-0.075,0.224-0.179,0.307-0.281
			c0.079-0.095,0.153-0.217,0.201-0.33c0.044-0.104,0.077-0.232,0.09-0.345c0.011-0.103,0.007-0.224-0.014-0.326
			c-0.018-0.093-0.053-0.197-0.098-0.281c-0.042-0.075-0.102-0.154-0.164-0.214c-0.057-0.052-0.128-0.103-0.198-0.134
			c-0.063-0.028-0.139-0.049-0.208-0.055c-0.059-0.004-0.128,0.001-0.186,0.019c-0.051,0.015-0.106,0.043-0.146,0.077
			c-0.035,0.029-0.07,0.072-0.09,0.114c-0.016,0.038-0.027,0.086-0.024,0.128c0.002,0.039,0.016,0.084,0.037,0.117
			c0.022,0.032,0.037,0.077,0.039,0.116c0.002,0.042-0.008,0.09-0.025,0.128c-0.019,0.042-0.054,0.085-0.089,0.115
			c-0.041,0.033-0.096,0.062-0.146,0.077c-0.058,0.018-0.127,0.024-0.187,0.019c-0.067-0.005-0.144-0.026-0.206-0.054
			c-0.069-0.032-0.142-0.083-0.199-0.135c-0.062-0.059-0.122-0.138-0.163-0.213c-0.045-0.083-0.082-0.188-0.099-0.28
			c-0.02-0.103-0.024-0.223-0.014-0.327c0.013-0.112,0.047-0.24,0.091-0.344c0.047-0.113,0.122-0.235,0.2-0.331
			c0.084-0.102,0.198-0.206,0.308-0.281c0.117-0.081,0.266-0.153,0.401-0.198c0.144-0.048,0.318-0.078,0.47-0.084
			c0.163-0.006,0.348,0.013,0.506,0.05c0.168,0.041,0.354,0.115,0.503,0.198c0.158,0.09,0.326,0.217,0.455,0.346
			c0.136,0.136,0.27,0.314,0.363,0.481c0.1,0.175,0.186,0.394,0.232,0.589c0.05,0.206,0.076,0.451,0.07,0.662
			c-0.008,0.222-0.05,0.476-0.114,0.688c-0.069,0.221-0.184,0.464-0.309,0.66c-0.131,0.204-0.311,0.417-0.49,0.58
			c-0.188,0.169-0.429,0.333-0.654,0.446c-0.233,0.118-0.521,0.215-0.778,0.267c-0.266,0.054-0.581,0.073-0.852,0.055
			c-0.281-0.019-0.601-0.085-0.868-0.178c-0.275-0.097-0.577-0.25-0.818-0.416C9.162,9.996,8.902,9.761,8.708,9.53
			C8.506,9.292,8.311,8.986,8.179,8.704C8.043,8.412,7.932,8.055,7.876,7.738C7.821,7.41,7.806,7.025,7.838,6.694
			C7.87,6.354,7.959,5.967,8.08,5.646c0.125-0.331,0.318-0.69,0.524-0.976c0.212-0.294,0.501-0.6,0.782-0.828
			c0.29-0.234,0.658-0.459,1-0.61c0.35-0.155,0.775-0.279,1.153-0.337c0.39-0.06,0.844-0.068,1.235-0.024
			c0.399,0.044,0.854,0.158,1.229,0.306c0.384,0.152,0.802,0.385,1.133,0.633c0.339,0.252,0.69,0.595,0.953,0.928
			c0.268,0.34,0.522,0.772,0.692,1.171c0.174,0.408,0.31,0.903,0.373,1.343c0.063,0.449,0.065,0.974,0.009,1.424
			c-0.058,0.46-0.195,0.98-0.371,1.41c-0.18,0.438-0.453,0.915-0.74,1.291c-0.294,0.385-0.689,0.781-1.073,1.076
			c-0.316,0.251-0.641,0.587-0.88,0.911c-0.233,0.316-0.453,0.714-0.597,1.081c-0.139,0.355-0.244,0.787-0.285,1.168
			c-0.04,0.37-0.029,0.802,0.029,1.171c0.058,0.357,0.177,0.76,0.326,1.091c0.144,0.321,0.36,0.67,0.583,0.941
			c0.217,0.266,0.507,0.536,0.787,0.734c0.271,0.193,0.61,0.373,0.922,0.488c0.303,0.111,0.668,0.192,0.988,0.221
			c0.312,0.026,0.673,0.012,0.98-0.045c0.297-0.054,0.63-0.16,0.902-0.29c0.265-0.127,0.549-0.31,0.769-0.501
			c0.215-0.184,0.433-0.428,0.59-0.662c0.15-0.225,0.292-0.508,0.378-0.765c0.084-0.249,0.143-0.546,0.157-0.808
			c0.016-0.252-0.006-0.543-0.059-0.789c-0.052-0.236-0.145-0.5-0.257-0.715c-0.105-0.206-0.261-0.426-0.418-0.597
			c-0.15-0.162-0.349-0.325-0.537-0.442c-0.18-0.111-0.405-0.212-0.607-0.271c-0.193-0.056-0.425-0.091-0.627-0.093
			c-0.192-0.003-0.413,0.024-0.599,0.074c-0.176,0.047-0.37,0.128-0.526,0.221c-0.147,0.088-0.305,0.212-0.423,0.336
			c-0.112,0.117-0.223,0.27-0.299,0.413c-0.07,0.135-0.13,0.302-0.161,0.45c-0.029,0.14-0.04,0.305-0.03,0.447
			c0.01,0.131,0.044,0.283,0.09,0.406c0.044,0.115,0.112,0.241,0.187,0.339c0.068,0.09,0.161,0.184,0.253,0.25
			c0.084,0.062,0.191,0.118,0.289,0.151c0.09,0.032,0.198,0.052,0.291,0.054c0.086,0.003,0.185-0.01,0.268-0.033
			c0.072-0.022,0.154-0.061,0.216-0.105c0.055-0.038,0.112-0.095,0.15-0.15c0.034-0.049,0.064-0.113,0.078-0.172
			c0.012-0.05,0.015-0.113,0.006-0.163c-0.007-0.046-0.028-0.098-0.055-0.135c-0.023-0.035-0.062-0.067-0.099-0.085
			C17.726,16.561,17.68,16.552,17.642,16.554z"
      />
    </SvgIcon>
  );
}
