import React from "react";

import AppMenu from "./AppMenu";

import Content from "./Content";
import Footer from "./Footer";

export default function Layout() {
  return (
    <AppMenu>
      <Content />
      <Footer />
    </AppMenu>
  );
}
