import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { WindowWidth } from "utils";
import { Promo } from "components/Layout";

const BackgroundWrapper = styled("div")(({ breakPoint }) => ({
  position: "relative",
  width: "100%",
  height: ["sm", "md"].includes(breakPoint) ? "60vh" : "70vh",
}));

const Background = styled("div")(({ url, position }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  backgroundImage: `url(/assets/img/${url})`,
  backgroundPosition: position ? position : "center",
  backgroundSize: "cover",
  zIndex: -1,
}));

const MainLogo = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  textAlign: "center",
  bottom: "10vh",
  "& img": {
    width: 600,
    maxWidth: "80%",
    margin: "auto",
    filter: "drop-shadow(3px 3px 2px #333)",
  },
}));

export default function PageHeader({ url, promo, position = "top", noLogo }) {
  /*make default background if no image needs to be given */

  let breakPoint = WindowWidth().breakPoint;

  if (!url && !promo) {
    return (
      <div
        style={{
          height: ["sm", "md"].includes(breakPoint) ? 60 : noLogo ? 72 : 134,
        }}
      />
    );
  }

  if (promo) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: 6,
        }}
      >
        <Grid item xs={12}>
          <MainLogo>
            <img src="/assets/img/logo/logo_main.svg" alt="royal spuds logo" />
          </MainLogo>
          <Promo />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: 6,
      }}
    >
      <Grid item xs={12}>
        <BackgroundWrapper breakPoint={breakPoint}>
          <Background
            url={url}
            position={position}
            breakPoint={breakPoint}
          ></Background>
        </BackgroundWrapper>
      </Grid>
    </Grid>
  );
}
