import React, { useEffect, useState } from "react";
import Papa from "papaparse";

export default function useImportShows() {
  const [data, setData] = useState([]);

  useEffect(() => {
    Papa.parse(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vTMV7ORAsp_C0GopFfw1iJ2Exb5Z-MwhbIx9Rl5hqoF9_X22UvX7PCSL2OtYG5HMAg4RrkIcjg1SAu0/pub?output=csv",
      {
        download: true,
        header: true,
        complete: function (results) {
          if (results.data) {
            setData(results.data);
          }
        },
      },
    );
  }, []);

  return Array.from(data);
}
