import React from "react";
import { styled } from "@mui/system";
import AlbumButtons from "./AlbumButtons";

import theme from "theme";

import { createAltText, WindowWidth } from "utils";
import {
  PageHeader,
  Section,
  Typography,
  Grid,
  SpotifyIcon,
  SoundCloudIcon,
  BandCampIcon,
  AppleIcon,
  YoutubeIcon,
  TriskelIcon,
  SpotifyPlayer,
} from "components/Layout";
import { albums } from "./constants";

function getIcon(link) {
  switch (link) {
    case "spotify":
      return <SpotifyIcon color="#ffffff" />;
    case "bandcamp":
      return <BandCampIcon color="#ffffff" />;
    case "soundcloud":
      return <SoundCloudIcon color="#ffffff" />;
    case "youtube":
      return <YoutubeIcon color="#ffffff" />;
    case "apple":
      return <AppleIcon color="#ffffff" />;
    case "celtic_world":
      return <TriskelIcon color="#ffffff" />;
    default:
      return null;
  }
}

export default function DiscographyList() {
  let breakPoint = WindowWidth().breakPoint;

  const AlbumWrapper = styled("div")((props) => ({
    width: "100%",
    overflow: "hidden",
    position: "relative",
    padding: theme.spacing(6),
  }));

  const MockSpotify = styled("div")((props) => ({
    width: `${props.width}px` ?? 0,
    height: `${props.height}px` ?? 0,
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: `${props.height}px` ?? 0,
  }));

  const AlbumBackgroundWrapper = styled("div")((props) => ({
    position: "absolute",
    height: "100%",
    width: "100%",
    clip: "rect(0, auto, auto, 0)",
    top: 0,
    left: 0,
  }));

  const AlbumBackground = styled("div")((props) => ({
    position: "fixed",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundImage: `url(${props.img})`,
    top: 0,
    left: 0,
    zIndex: -1,
    "&::after": {
      content: '""',
      position: "fixed",
      height: "100vh",
      width: "100%",
      top: 0,
      left: 0,
      display: "block",
      background: `rgb(0,0,0,.7)`,
    },
  }));

  return (
    <>
      <PageHeader />
      <Section noBackground noPadding>
        {albums.map((album, index) => {
          return (
            <AlbumWrapper key={index}>
              <AlbumBackgroundWrapper>
                <AlbumBackground img={`/assets/img/albums/${album.img}`} />
              </AlbumBackgroundWrapper>

              <Grid
                container
                justifyContent="center"
                sx={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={["sm", "md"].includes(breakPoint) ? 12 : "auto"}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={`/assets/img/albums/${album.img}`}
                        alt={createAltText(album.img)}
                        style={{
                          width: ["sm", "md"].includes(breakPoint)
                            ? "300px"
                            : "400px",
                          borderRadius: "2px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={["sm", "md"].includes(breakPoint) ? 12 : "auto"}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {album.embed && (
                        <SpotifyPlayer
                          url={album.embed}
                          type={album.embedType ?? "album"}
                          height={
                            ["sm", "md"].includes(breakPoint) ? "152" : "400"
                          }
                          width={
                            ["sm", "md"].includes(breakPoint) ? "300" : "400"
                          }
                        />
                      )}
                      {!album.embed &&
                        ["sm", "md"].indexOf(breakPoint) === -1 && (
                          <MockSpotify height="400" width="300">
                            <Typography
                              variant="h6"
                              sx={{ lineHeight: "inherit" }}
                            >
                              No preview available
                            </Typography>
                          </MockSpotify>
                        )}
                    </Grid>
                  </Grid>
                  <AlbumButtons listen={album.listen} buy={album.buy} />
                </Grid>
              </Grid>
            </AlbumWrapper>
          );
        })}
      </Section>
    </>
  );
}
