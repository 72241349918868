import React, { useState } from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import PhotoDialog from "./PhotoDialog";

import { WindowWidth } from "utils";
import { authors } from "./constants";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function PhotoReel({ collection = [], mainAuthor }) {
  const mainCredenials = authors[mainAuthor] ?? null;

  const [imgCredentials, setImgCredentials] = useState({
    img: "",
    credentials: mainCredenials,
  });

  let breakPoint = WindowWidth().breakPoint;

  return (
    <>
      <ImageList
        sx={{
          cursor: "pointer",
        }}
        variant="quilted"
        cols={breakPoint === "sm" ? 2 : 4}
      >
        {collection.map((photo, index) => (
          <ImageListItem
            key={`${photo.img}_${index}`}
            cols={photo.cols || 1}
            rows={photo.rows || 1}
          >
            <img
              {...srcset(photo.img, 121, photo.rows, photo.cols)}
              alt={photo.title}
              loading="lazy"
              onClick={() => {
                setImgCredentials({
                  img: photo.img,
                  authors: authors[photo.author],
                });
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <PhotoDialog
        imgUrl={imgCredentials.img}
        setImgCredentials={setImgCredentials}
        credentials={mainCredenials ?? imgCredentials.authors}
      />
    </>
  );
}
