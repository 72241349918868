import React from "react";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { WindowWidth } from "utils";

import { Box } from "components/Layout";

const BandLogoIcon = styled("div")(() => ({
  width: "50px",
  display: "inline-block",
  "& img": {
    width: "100%",
    margin: "auto",
  },
}));

const BandLogoTitle = styled("div")(() => ({
  width: "240px",
  display: "inline-block",
  marginLeft: 10,
  "& img": {
    width: "100%",
    margin: "auto",
  },
}));

export default function HeaderLogo() {
  const navigate = useNavigate();
  let breakPoint = WindowWidth().breakPoint;

  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        navigate("/");
      }}
    >
      <BandLogoIcon
        sx={{
          width: ["xs", "sm", "md"].includes(breakPoint) ? "35px" : "50px",
          ...(["xs", "sm", "md"].includes(breakPoint) && { marginTop: "6px" }),
        }}
      >
        <img
          src="/assets/img/logo/logo_potato.svg"
          alt="the Royal Spuds icon"
        />
      </BandLogoIcon>
      <BandLogoTitle
        sx={{
          width: breakPoint === "md" ? "170px" : "240px",
          ...(["xs", "sm"].includes(breakPoint) && { display: "none" }),
        }}
      >
        <img src="/assets/img/logo/logo_main_text.svg" alt="the Royal Spuds" />
      </BandLogoTitle>
    </Box>
  );
}
