import React from "react";

import DiscographyList from "./DiscographyList";

import { ContentPending, Link } from "components/Layout";

import { useParams } from "react-router-dom";

export default function DiscographyPage() {
  let { album } = useParams();

  return <DiscographyList />;
}
