import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// 100%	FF
// 95%	F2
// 90%	E6
// 85%	D9
// 80%	CC
// 75%	BF
// 70%	B3
// 65%	A6
// 60%	99
// 55%	8C
// 50%	80
// 45%	73
// 40%	66
// 35%	59
// 30%	4D
// 25%	40
// 20%	33
// 15%	26
// 10%	1A
// 5%	0D
// 0%	00

const black = "#000000";
const darkGrey = "#121212";
const grey = "#666666";
const white = "#ffffff";
const orange = "#f5841f";
const darkOrange = "#f55f1f";
const green = "#0d813c";
const darkGreen = "#0a612d";

const fallbackFonts = [
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

function createFontFamily(fontFamily, theme) {
  // if (!theme) {
  //   return {};
  // }
  return {
    h1: { fontFamily },
    h2: {
      fontFamily,
      // [theme.breakpoints.down("md")]: {
      //   fontSize: "3rem",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: "2.4rem",
      // },
    },
    h3: { fontFamily },
    h4: { fontFamily },
    h5: { fontFamily },
    h6: {
      fontFamily,
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: "1rem",
      // },
    },
    subtitle1: { fontFamily },
    subtitle2: { fontFamily },
    body1: {
      fontFamily,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".9rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "2.5rem",
      },
    },
    body2: { fontFamily },
    button: { fontFamily },
    caption: { fontFamily },
    overline: { fontFamily },
  };
}

let theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: darkGrey,
      main: black,
      dark: black,
      contrastText: white,
    },
    secondary: {
      light: white,
      main: white,
      contrastText: black,
    },
    colors: {
      orange: {
        main: orange,
      },
      green: {
        main: green,
      },
    },
    background: {
      default: black,
    },
    text: {
      black: {
        primary: white,
        secondary: grey,
      },
      green: {
        primary: "#ffffff",
        secondary: "#ffffff",
      },
      white: {
        primary: "#121212",
        secondary: "#666666",
        tertiary: "#000000",
      },
    },
  },
  MuiTabs: {
    secondary: orange,
  },
  typography: {
    fontFamily: ["Signika Negative", ...fallbackFonts].join(","),
    dekko: {
      fontFamily: ["Dekko", ...fallbackFonts].join(","),
    },
    signika: {
      fontFamily: ["Signika Negative", ...fallbackFonts].join(","),
    },
    permanentMarker: {
      fontFamily: ["Permanent Marker", ...fallbackFonts].join(","),
    },
    rubik: {
      fontFamily: ["Rubik", ...fallbackFonts].join(","),
    },
    rubikDirt: {
      fontFamily: ["Rubik Dirt", ...fallbackFonts].join(","),
    },
  },
  components: {
    MuiCssBaseline: {},
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 2,
          borderWidth: 2,
          "&:hover": {
            borderWidth: 2,
          },
        }),
        containedPrimary: {
          background: orange,
          "&:hover": {
            background: darkOrange,
          },
        },
        containedSecondary: {
          color: white,
          background: green,
          "&:hover": {
            background: darkGreen,
          },
        },
        outlinedPrimary: {
          borderColor: black,
          color: black,
          "&:hover": {
            background: "#00000032",
          },
        },
        outlinedSecondary: {
          borderColor: white,
          color: white,
          "&:hover": {
            background: "#ffffff32",
          },
        },
      },
    },
    Link: {
      cursor: "pointer",
    },
  },
});

theme.typography.body1 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "1.0rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
};

theme.typography.body2 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.0rem",
  },
};

export default theme;
