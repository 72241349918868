import React from "react";
import MuiTypography from "@mui/material/Typography";

function makeTitleColor(type, theme) {
  switch (type) {
    case "orange":
      return theme.palette.colors.orange.main;
    case "green":
      return theme.palette.colors.green.main;
    case "white":
      return theme.palette.primary.contrastText;
    case "black":
      return theme.palette.secondary.contrastText;
    case "bg-black":
    case "bg-green":
      return theme.palette.primary.contrastText;
    default:
      return theme.palette.secondary.contrastText;
  }
}

export default function Typography({
  children,
  background,
  color,
  family = "signika",
  typographySx = {},
  ...props
}) {
  return (
    <MuiTypography
      sx={{
        color: (theme) => makeTitleColor(color ?? `bg-${background}`, theme),
        ...(family && { fontFamily: (theme) => theme.typography[family] }),
        ...typographySx,
      }}
      {...props}
    >
      {children}
    </MuiTypography>
  );
}
