import React from "react";

import { Grid, PlayerYoutube } from "../Layout";
import { WindowWidth } from "utils";

export default function VideoReel({ videos = [], preview }) {
  let breakPoint = WindowWidth().breakPoint;

  if (videos.length === 0) {
    return null;
  }

  return (
    <>
      {preview && ["sm", "md"].includes(breakPoint) === true && (
        <Grid container direction="row">
          {
            <Grid item xs={12}>
              <PlayerYoutube id={videos[0]} />
            </Grid>
          }
        </Grid>
      )}
      {preview && ["sm", "md"].includes(breakPoint) === false && (
        <Grid container direction="row">
          {videos.map((video, index) => {
            return (
              <Grid item xs={12} md={6} key={index}>
                <PlayerYoutube id={video} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {!preview && (
        <Grid container direction="row">
          {videos.map((video, index) => {
            return (
              <Grid
                item
                xs={12}
                md={breakPoint !== "sm" ? 6 : 12}
                key={index}
                mb={2}
              >
                <PlayerYoutube id={video} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}
