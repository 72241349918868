import React, { useEffect } from "react";
import { WindowWidth } from "utils";
import {
  Section,
  Grid,
  Button,
  Typography,
  PageHeader,
  Link,
} from "components/Layout";

export default function ContactPage() {
  let breakPoint = WindowWidth().breakPoint;

  useEffect(() => {
    try {
      window.srv.open({
        key: "dc03e02d937d0f4957d2e6578671d267b136ab18",
        domain: "demo.mopinion.com",
        divName: "booking",
        forceType: "embed",
      });
    } catch (x) {}
  }, []);

  return (
    <>
      <PageHeader />

      <Section
        background="black"
        title="Bookings"
        sizeProps={{ xs: ["sm", "md"].includes(breakPoint) ? 0 : 8 }}
      >
        <Grid container justifyContent="center">
          <Grid item textAlign="center" mb={4} xs={12}>
            <Typography variant="h6" background="black" gutterBottom>
              Do you want to book the Royal Spuds? Well you can!
              <br />
              Fill in the form below and we will get in contact as soon as
              possible.
            </Typography>
          </Grid>

          <Grid item textAlign="center" mb={4} xs={12}>
            <Typography variant="body1" background="black">
              <i>
                Already booked us, but want to promote your event? Check the
                download section below.
              </i>
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <div id="booking"></div>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Typography background="black">
              You can also mail to:
              <br />
              <br />
              <Link href="mailto:royalspudsband@gmail.com">
                royalspudsband@gmail.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section
        background="white"
        title="Downloads"
        sizeProps={{ xs: ["sm", "md"].includes(breakPoint) ? 0 : 8 }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} textAlign="center" mb={4}>
            <Typography background="white">
              For bookers & stagemanagers, crew, or just anyone interested we
              offer the following downloads:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} textAlign="center">
            <Button
              variant="contained"
              href="https://drive.google.com/drive/folders/0B7m9mNJIVzYEVnVueHp0N3B5dHM?resourcekey=0-C4JKWDeQyEvGqu4ZMYYwvw"
              size="large"
              color="secondary"
              sx={{ margin: 2, minWidth: 200 }}
            >
              Technical Rider
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} textAlign="center">
            <Button
              variant="contained"
              href="https://drive.google.com/drive/folders/1_K1h_H6OeMFAW7UteKA-pMqOXC0sjOH6"
              size="large"
              color="secondary"
              sx={{ margin: 2, minWidth: 200 }}
            >
              Presskit
            </Button>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}
