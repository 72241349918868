import React from "react";

export default function SpotifyPlayer({
  url,
  type = "album",
  height = "100%",
  width = "100%",
}) {
  return (
    <iframe
      src={`https://open.spotify.com/embed/${type}/${url}`}
      width={width}
      height={height}
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media"
      loading="lazy"
      style={{ borderRadius: "2px" }}
    ></iframe>
  );
}
