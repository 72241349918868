export const albums = [
  {
    type: "album",
    title: "Roots of life",
    img: "roots_of_life.png",
    embed: "7aC44HP8aA9CUY2NH8E0aM?utm_source=generator",
    release: "2022",
    tracks: "8",
    link: "/discography/roots_of_life",
    listen: {
      spotify: "https://open.spotify.com/album/7aC44HP8aA9CUY2NH8E0aM",
      bandcamp: "https://theroyalspuds.bandcamp.com/album/roots-of-life",
    },
    buy: {
      bandcamp: "https://theroyalspuds.bandcamp.com/album/roots-of-life",
      apple: "https://music.apple.com/nl/album/roots-of-life/1615146455",
    },
  },
  {
    type: "single",
    title: "Alien",
    img: "alien.png",
    release: "2022",
    tracks: "1",
    link: "/discography/alien",
    embed: "6bZuEcaItnYTbvTE4HeLa9?utm_source=generator",
    listen: {
      bandcamp: "https://theroyalspuds.bandcamp.com/track/alien",
      spotify: "https://open.spotify.com/album/6bZuEcaItnYTbvTE4HeLa9",
    },
    buy: {
      apple: "https://music.apple.com/nl/album/alien-single/1601530776",
    },
  },
  {
    type: "single",
    title: "Couch potato specials",
    img: "couch_potato_specials.png",
    release: "2020",
    tracks: "3",
    link: "/discography/couch_potato_specials",
    listen: {
      bandcamp:
        "https://theroyalspuds.bandcamp.com/album/the-couch-potato-specials",
    },
    buy: {
      bandcamp:
        "https://theroyalspuds.bandcamp.com/album/the-couch-potato-specials",
    },
  },
  {
    type: "album",
    title: "Unforgotten Lore",
    img: "unforgotten_lore.png",
    release: "2018",
    tracks: "7",
    link: "/discography/unforgotten_lore",
    embed: "7nNgsRyiKm8B29JUaOiAOx?utm_source=generator",
    listen: {
      spotify:
        "https://open.spotify.com/album/7nNgsRyiKm8B29JUaOiAOx?si=NGpKhYn2QWq79XmZhbekFg",
      bandcamp: "https://theroyalspuds.bandcamp.com/album/unforgotten-lore",
    },
    buy: {
      apple: "https://music.apple.com/nl/album/unforgotten-lore/1445497230",
      celtic_world:
        "https://www.celticworld.nl/cd-s-en-dvd-s/cd-s/the-royal-spuds/",
    },
  },
  {
    type: "single",
    title: "A man you won't meet every day",
    img: "single_a_man.png",
    release: "2018",
    tracks: "1",
    link: "/discography/a_man",
    embed: "0h0iudzo2TKGk5FIpYY9hz?utm_source=generator&theme=0",
    embedType: "track",
    listen: {},
    buy: {},
  },
  {
    type: "album",
    title: "It's a feckin' Freakshow",
    img: "freakshow.png",
    release: "2015",
    tracks: "11",
    link: "/discography/freakshow",
    embed: "1QSwNGqqUATIccUjcP90GE?utm_source=generator",
    listen: {
      spotify:
        "https://open.spotify.com/album/1QSwNGqqUATIccUjcP90GE?si=_o4PNbFJR5G_pzkZa4XP4A",
      bandcamp:
        "https://theroyalspuds.bandcamp.com/album/its-a-feckin-freakshow",
    },
    buy: {
      apple:
        "https://music.apple.com/nl/album/its-a-feckin-freakshow/1182259807",
      celtic_world:
        "https://www.celticworld.nl/cd-s-en-dvd-s/cd-s/the-royal-spuds/",
    },
  },
  {
    type: "album",
    title: "Wanted, drunk 'n' alive",
    img: "wanted.png",
    release: "2013",
    tracks: "10",
    link: "/discography/wanted",
    embed: "3wId267AeYQsALvqx9ut2l?utm_source=generator",
    listen: {
      spotify:
        "https://open.spotify.com/album/3wId267AeYQsALvqx9ut2l?si=rOP6FIW0TZWs2hZ-XwNQNg",
    },
    buy: {
      bandcamp: "https://theroyalspuds.bandcamp.com/album/wanted-drunk-n-alive",
      apple: "https://music.apple.com/nl/album/wanted-drunk-n-alive/824955591",
    },
  },
  {
    type: "single",
    title: "Start your engines",
    img: "start_engines.png",
    release: "2012",
    tracks: "5",
    link: "/discography/start_your_engines",
    listen: {},
    buy: {},
  },
];
