import React from "react";

import theme from "theme";
import { useNavigate } from "react-router-dom";

import { PhotoReel, Credentials } from "components/Media";
import { VideoReel } from "components/Media";
import { PageHeader, Section, Grid, Button } from "components/Layout";
import { ShowsList } from "components/Shows";
import { WindowWidth } from "utils";

export default function HomePage() {
  let breakPoint = WindowWidth().breakPoint;
  let smallMedium = ["sm", "md"].includes(breakPoint);
  const navigate = useNavigate();

  const { preview } = Credentials;

  return (
    <>
      <PageHeader promo />

      <Section background="black" title="Upcoming shows">
        <ShowsList upcoming preview background="black" />
        <Grid item xs={12} textAlign="center" mt={4}>
          <Button
            fullWidth={smallMedium}
            sx={{
              minWidth: !smallMedium ? theme.spacing(40) : 0,
              marginBottom: smallMedium ? 2 : 0,
            }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/booking");
            }}
          >
            Book the Royal Spuds
          </Button>
          <Button
            fullWidth={smallMedium}
            sx={{
              minWidth: !smallMedium ? theme.spacing(40) : 0,
              marginLeft: smallMedium ? 0 : 2,
            }}
            size="large"
            variant="outlined"
            color="secondary"
            to="/shows"
            background="black"
          >
            Show past shows
          </Button>
        </Grid>
      </Section>

      <Section background="white" title="Media">
        <Grid container justifyContent="center" rowSpacing={4}>
          <Grid item xs={12}>
            <VideoReel videos={["ezM-2KETqds", "tpG-dQYQ1wc"]} preview />
          </Grid>

          <Grid item textAlign="center">
            <Button
              sx={{
                minWidth: theme.spacing(smallMedium ? 0 : 40),
              }}
              fullWidth={smallMedium}
              variant="outlined"
              color="primary"
              to="/media/videos"
              background="white"
              size="large"
            >
              Show more videos
            </Button>
          </Grid>
        </Grid>
      </Section>

      <Section background="white">
        <Grid container justifyContent="center" rowSpacing={4}>
          <Grid item>
            <PhotoReel collection={preview} />
          </Grid>

          <Grid item textAlign="center">
            <Button
              fullWidth={smallMedium}
              sx={{
                minWidth: theme.spacing(smallMedium ? 0 : 40),
              }}
              variant="outlined"
              color="primary"
              to="/media/photos"
              background="white"
              size="large"
            >
              Show more photos
            </Button>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}
