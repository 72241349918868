import React, { useState } from "react";

import { useTheme } from "@mui/material/styles";

import { styled } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { Socials, Typography, Grid } from "components/Layout";
import HeaderLogo from "./HeaderLogo";

import { WindowScroll, WindowWidth } from "utils";

const showLogoAt = 100;

const LogoWrapper = styled("div")((props) => ({
  opacity: props.scrollTop < showLogoAt ? 1 : 0,
  marginTop: props.scrollTop < showLogoAt ? 12 : 0,
  height: props.scrollTop < showLogoAt ? 50 : 0,
  transition: "all ease-in-out .2s",
}));

const LogoWrapperMobile = styled("div")((props) => ({
  marginTop: 10,
  height: 50,
}));

const SocialWrapper = styled("div")((props) => ({
  position: "absolute",
  right: 20,
  zIndex: 10,
  top: 10,
  marginTop: props.scrollTop < showLogoAt ? 5 : 0,
  transition: "all ease-in-out .2s",
}));

const SocialWrapperMobile = styled("div")((props) => ({
  position: "absolute",
  right: 20,
  zIndex: 10,
  top: 0,
  marginTop: 7,
  transition: "all ease-in-out .2s",
}));

const menuList = [
  // {
  //   title: "Home",
  //   to: "/",
  // },
  {
    title: "Shows",
    to: "/shows",
  },
  {
    title: "Music",
    to: "/discography/list",
  },
  {
    title: "Media",
    to: "/media/photos",
  },
  {
    title: "Band",
    to: "/band",
  },
  {
    title: "Booking",
    to: "/booking",
  },
];

export default function AppMenu({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();

  const theme = useTheme();

  let scrollTop = WindowScroll().scrollTop;
  let windowWidth = WindowWidth().breakPoint;

  const alwaysHideLogo = ["/", "/band"].includes(location.pathname);
  const drawerWidth = 240;

  const selectedTab =
    location.pathname === "/"
      ? false
      : location.pathname.includes("media")
      ? "/media/photos"
      : location.pathname;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: (theme) => theme.palette.primary.dark,
          backgroundImage:
            'linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("/assets/img/backgrounds/background_black.jpg")',
          boxShadow: "none",
        }}
      >
        {["sm", "md"].includes(windowWidth) && (
          <SocialWrapperMobile windowWidth={windowWidth} scrollTop={scrollTop}>
            <Socials size={28} />
          </SocialWrapperMobile>
        )}
        {["lg", "xl"].includes(windowWidth) && (
          <SocialWrapper windowWidth={windowWidth} scrollTop={scrollTop}>
            <Socials size={28} />
          </SocialWrapper>
        )}

        <Toolbar>
          {["sm", "md"].includes(windowWidth) && (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <LogoWrapperMobile scrollTop={scrollTop}>
                <HeaderLogo />
              </LogoWrapperMobile>
            </>
          )}
          {["lg", "xl"].includes(windowWidth) && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                {!alwaysHideLogo && (
                  <LogoWrapper scrollTop={scrollTop}>
                    <HeaderLogo />
                  </LogoWrapper>
                )}
              </Grid>
              <Grid item>
                <Tabs
                  onChange={(e, to) => {
                    navigate(to, {});
                  }}
                  value={selectedTab}
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  {menuList.map((listItem, index) => {
                    return (
                      <Tab
                        key={index}
                        label={listItem.title}
                        icon={
                          index > 0 ? (
                            <img
                              src="/assets/img/icons/clover.svg"
                              style={{
                                height: windowWidth === "xl" ? 14 : 10,
                                paddingRight: windowWidth === "xl" ? 20 : 5,
                                opacity: 0.2,
                              }}
                              alt="clover"
                            />
                          ) : null
                        }
                        disableRipple
                        iconPosition="start"
                        textColor="primary"
                        value={listItem.to}
                        sx={{
                          fontSize: windowWidth === "xl" ? 24 : 18,
                          transition: "ease .2s",
                          paddingLeft: windowWidth === "xl" ? 2 : 0,
                          minWidth: 0,
                          "&:hover": {
                            color: theme.palette.secondary.main,
                          },
                          "&.Mui-selected": {
                            color: theme.palette.colors.orange.main,
                          },
                          "& .MuiTabs-indicator": {
                            display: "none",
                          },
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: (theme) => theme.palette.primary.dark,
            backgroundImage:
              'linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("/assets/img/backgrounds/background_black.jpg")',
          },
        }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {
          <List
            sx={{
              marginTop: 6,
            }}
          >
            {menuList.map((listItem, index) => {
              return (
                <ListItem
                  disablePadding
                  component={Link}
                  to={listItem.to}
                  key={index}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 34,
                      paddingLeft: 2,
                    }}
                  >
                    <img
                      src="/assets/img/icons/clover.svg"
                      style={{
                        height: 10,
                        paddingLeft: 2,
                        opacity: 0.2,
                      }}
                      alt="clover"
                    />
                  </ListItemIcon>
                  <ListItemButton
                    onClick={() => setMenuOpen(false)}
                    selected={location.pathname === listItem.to}
                    sx={{
                      color: theme.palette.primary.contrastText,
                      "&.Mui-selected h6": {
                        color: theme.palette.colors.orange.main,
                      },
                    }}
                  >
                    <ListItemText disableTypography>
                      <Typography variant="h6" background="black">
                        {listItem.title}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        }
      </Drawer>
      {children}
    </Box>
  );
}
