import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PageHeader, Section, Button, Grid } from "components/Layout";
import ShowsList from "./ShowsList";

import { useImportShows } from "components/Shows";

export default function ShowsPage({ ...props }) {
  const shows = useImportShows() ?? [];

  const navigate = useNavigate();

  const showsPlayed = shows.length;
  const [maxShowsInView, setMaxShowsInView] = useState(10);

  return (
    <>
      <PageHeader url="header_shows.jpg" position="top center" />
      <Section background="white" title="Upcoming shows">
        <ShowsList upcoming background="white" />
        <Grid item xs={12} textAlign="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              navigate("/booking");
            }}
          >
            Book the Royal Spuds
          </Button>
        </Grid>
      </Section>

      <Section background="white" title="Past shows">
        <ShowsList past background="white" maxShowsInView={maxShowsInView} />
        {showsPlayed > maxShowsInView && (
          <Grid item textAlign="center" mt={4}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setMaxShowsInView(maxShowsInView + 10)}
            >
              Show more
            </Button>
          </Grid>
        )}
      </Section>
    </>
  );
}
