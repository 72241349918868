import React, { useState, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/system";

import { Link } from "components/Layout";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogImage = styled("img")(() => ({
  width: "100%",
  maxHeight: "80vh",
  margin: "auto",
}));

export default function PhotoDialog({
  imgUrl,
  credentials,
  setImgCredentials = () => {},
}) {
  return (
    <Dialog
      maxWidth={"lg"}
      open={imgUrl}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setImgCredentials({
          img: "",
          credentials: {},
        });
      }}
    >
      <IconButton
        aria-label="close"
        size="large"
        onClick={() => {
          setImgCredentials({
            img: "",
            credentials: {},
          });
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          paddingBottom: 0,
        }}
      >
        <DialogImage src={imgUrl} alt={imgUrl} />
      </DialogContent>
      <DialogActions
        sx={{
          paddingRight: 3,
          "& a": {
            marginLeft: 1,
          },
        }}
      >
        Photo by:
        <Link href={credentials?.url ?? "#"}>{credentials?.name ?? ""}</Link>
      </DialogActions>
    </Dialog>
  );
}
