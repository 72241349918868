import React from "react";

import { useParams } from "react-router-dom";

import PhotoReel from "./PhotoReel";
import {
  PageHeader,
  Section,
  Grid,
  Typography,
  Link,
  Button,
} from "components/Layout";
import { photoGallery, authors } from "./constants";
import { WindowWidth } from "utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function MediaDetailPage() {
  const { collection } = useParams();
  let breakPoint = WindowWidth().breakPoint;
  let isMobile = ["sm", "md"].includes(breakPoint);

  const gallery = photoGallery.find((gallery) => gallery.to === collection);

  return (
    <>
      <PageHeader />

      <Section type="white">
        <Grid container>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="large"
              mb={2}
              startIcon={<ArrowBackIcon />}
              to="/media/photos"
            >
              Back to gallery
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={isMobile ? "h4" : "h3"}
              family="rubikDirt"
              background="white"
            >
              {gallery?.title ?? ""}
            </Typography>
            <Link href={authors[gallery.author].url} variant="h6">
              By {authors[gallery.author].name}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <PhotoReel
              mainAuthor={gallery.author}
              collection={
                isMobile ? gallery.collectionMobile : gallery.collection
              }
            />
          </Grid>
        </Grid>
      </Section>
    </>
  );
}
