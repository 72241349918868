const basePath = "../assets/img/gallery";

export const authors = {
  marielle: {
    name: "Marielle Grootobink",
    url: "https://mariellegrootobbink.wixsite.com/mariellegrootobbink",
  },
  angie: {
    name: "fotografie met Angie",
    url: "https://www.fotografiemetangie.com/",
  },
};

export const preview = [
  {
    img: `${basePath}/melkweg_2019/melkweg_08.jpg`,
    title: "Melkweg",
    author: "marielle",
    cols: 2,
    rows: 2,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_24.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_22.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_17.jpg`,
    title: "Melkweg",
    author: "marielle",
    cols: 2,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_06.jpg`,
    title: "Melkweg",
    author: "marielle",
    cols: 2,
    rows: 2,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_05.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_19.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_17.jpg`,
    title: "Bibelot",
    author: "marielle",
    cols: 2,
    rows: 2,
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_05.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_02.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_14.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_01.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_16.jpg`,
    title: "Keltfest",
    author: "marielle",
    cols: 2,
    rows: 2,
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_10.jpg`,
    title: "Keltfest",
    author: "marielle",
    cols: 2,
    rows: 2,
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_06.jpg`,
    title: "Keltfest",
    author: "marielle",
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_08.jpg`,
    title: "Keltfest",
    author: "marielle",
  },
];

export const previewMobile = [
  {
    img: `${basePath}/melkweg_2019/melkweg_08.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_24.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_22.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_17.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_06.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_05.jpg`,
    title: "Melkweg",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_19.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_17.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_05.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_02.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_14.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/bibelot_2020/bibelot_01.jpg`,
    title: "Bibelot",
    author: "marielle",
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_16.jpg`,
    title: "Keltfest",
    author: "marielle",
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_10.jpg`,
    title: "Keltfest",
    author: "marielle",
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_06.jpg`,
    title: "Keltfest",
    author: "marielle",
  },
  {
    img: `${basePath}/keltfest_2019/keltfest_08.jpg`,
    title: "Keltfest",
    author: "marielle",
  },
];

export const windschoten = [
  {
    img: `${basePath}/windschoten_2023/windschoten_15.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_02.jpg`,
    cols: 2,
    rows: 2,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_03.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_04.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_06.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_05.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_07.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_08.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_09.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_10.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_11.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_01.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_12.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_14.jpg`,
  },
];

export const windschotenMobile = [
  {
    img: `${basePath}/windschoten_2023/windschoten_15.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_02.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_03.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_04.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_06.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_05.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_07.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_08.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_09.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_10.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_11.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_01.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_12.jpg`,
  },
  {
    img: `${basePath}/windschoten_2023/windschoten_14.jpg`,
  },
];

export const fantasyfest = [
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_01.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_43.jpg`,
    cols: 3,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_30.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_02.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_27.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_35.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_03.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_21.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_07.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_09.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_10.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_11.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_14.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_13.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_15.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_06.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_12.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_16.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_17.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_08.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_04.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_05.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_18.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_19.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_22.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_42.jpg`,
    rows: 2,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_20.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_23.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_24.jpg`,
  },

  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_25.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_26.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_28.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_29.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_31.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_32.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_33.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_34.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_36.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_37.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_38.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_39.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_40.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_41.jpg`,
    cols: 3,
  },
];

export const fantasyfestMobile = [
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_01.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_43.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_30.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_02.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_27.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_35.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_03.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_21.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_07.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_09.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_10.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_11.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_14.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_13.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_15.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_06.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_12.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_16.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_17.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_08.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_04.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_05.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_18.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_19.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_22.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_42.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_20.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_23.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_24.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_25.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_26.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_28.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_29.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_31.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_32.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_33.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_34.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_36.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_37.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_38.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_39.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_40.jpg`,
  },
  {
    img: `${basePath}/fantasyfest_2022/fantasyfest_41.jpg`,
  },
];

export const bibelot = [
  {
    img: `${basePath}/bibelot_2020/bibelot_01.jpg`,
  },
];

export const bibelotMobile = [
  {
    img: `${basePath}/bibelot_2020/bibelot_01.jpg`,
  },
];

export const eallum = [
  {
    img: `${basePath}/eallum_2023/eallum_09.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_03.jpg`,
    cols: 3,
  },
  {
    img: `${basePath}/eallum_2023/eallum_02.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/eallum_2023/eallum_04.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_05.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_06.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_07.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_08.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_15.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_10.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_11.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/eallum_2023/eallum_12.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_13.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_14.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_01.jpg`,
    cols: 2,
  },
];

export const anniversary = [
  {
    img: `${basePath}/10years_anniversary_2022/10years_41.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_01.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_04.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_05.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_36.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_06.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_07.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_09.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_10.jpg`,
    cols: 3,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_12.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_02.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_13.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_08.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_14.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_15.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_17.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_18.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_30.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_40.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_03.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_19.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_20.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_25.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_21.jpg`,
    cols: 2,
    rows: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_11.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_22.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_23.jpg`,
  },

  {
    img: `${basePath}/10years_anniversary_2022/10years_26.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_27.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_35.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_38.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_39.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_33.jpg`,
    cols: 3,
  },
];

export const anniversaryMobile = [
  {
    img: `${basePath}/10years_anniversary_2022/10years_41.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_01.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_04.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_05.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_36.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_06.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_07.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_09.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_10.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_12.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_02.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_13.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_08.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_14.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_15.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_17.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_18.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_30.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_40.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_03.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_19.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_20.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_25.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_21.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_11.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_22.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_23.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_26.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_27.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_35.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_38.jpg`,
  },
  {
    img: `${basePath}/10years_anniversary_2022/10years_39.jpg`,
  },
];

export const eallumMobile = [
  {
    img: `${basePath}/eallum_2023/eallum_09.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_03.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_02.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_04.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_05.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_06.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_07.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_08.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_15.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_10.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_11.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_12.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_13.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_14.jpg`,
  },
  {
    img: `${basePath}/eallum_2023/eallum_01.jpg`,
  },
];

export const melkweg = [
  {
    img: `${basePath}/melkweg_2019/melkweg_19.jpg`,
    cols: 3,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_01.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_14.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_02.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_04.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_16.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_22.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_05.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_07.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_10.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_03.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_09.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_11.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_12.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_13.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_23.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_17.jpg`,
    cols: 3,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_15.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_06.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_18.jpg`,
    cols: 2,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_08.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_20.jpg`,
    cols: 3,
  },
];

export const melkwegMobile = [
  {
    img: `${basePath}/melkweg_2019/melkweg_19.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_01.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_14.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_02.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_04.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_16.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_22.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_05.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_07.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_10.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_03.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_09.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_11.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_12.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_13.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_23.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_17.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_15.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_06.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_18.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_08.jpg`,
  },
  {
    img: `${basePath}/melkweg_2019/melkweg_20.jpg`,
  },
];

export const photoGallery = [
  {
    title: "Windschoten 2023",
    image: `${basePath}/windschoten_2023/windschoten_preview.jpg`,
    to: "windschoten2023",
    author: "marielle",
    collection: windschoten,
    collectionMobile: windschotenMobile,
  },
  {
    title: "Eallum 2023",
    image: `${basePath}/eallum_2023/eallum_preview.jpg`,
    to: "eallum2023",
    author: "angie",
    collection: eallum,
    collectionMobile: eallumMobile,
  },
  {
    title: "Fantasyfest 2022",
    image: `${basePath}/fantasyfest_2022/fantasyfest_preview.jpg`,
    to: "fantasyfest2022",
    author: "marielle",
    collection: fantasyfest,
    collectionMobile: fantasyfestMobile,
  },
  {
    title: "10th year anniversary 2022",
    image: `${basePath}/10years_anniversary_2022/10years_preview.jpg`,
    to: "10yearanniversary2022",
    author: "marielle",
    collection: anniversary,
    collectionMobile: anniversaryMobile,
  },
  {
    title: "Bibelot 2020",
    image: `${basePath}/bibelot_2020/bibelot_preview.jpg`,
    to: "10yearanniversary2022",
    author: "marielle",
    collection: bibelot,
    collectionMobile: bibelotMobile,
  },
  {
    title: "Melkweg 2019",
    image: `${basePath}/melkweg_2019/melkweg_preview.jpg`,
    to: "melkweg2019",
    author: "marielle",
    collection: melkweg,
    collectionMobile: melkwegMobile,
  },
];
