import React from "react";
import { styled } from "@mui/system";

import { Grid, Section, Socials, Typography } from "components/Layout";
import { WindowWidth } from "utils";

const spudIconSize = 50;
const spudIconSizeSmall = 40;

const SpudIconDeskTop = styled("div")(() => ({
  position: "absolute",
  top: 0,
  width: 50,
  left: `calc(50% - ${spudIconSize / 2}px)`,
  "& img": {
    width: spudIconSize,
    margin: "auto",
  },
}));

const SpudIconMobile = styled("div")(() => ({
  top: 0,
  width: "100%",
  textAlign: "center",
  "& img": {
    width: spudIconSizeSmall,
    margin: "auto",
  },
}));

export default function Footer() {
  let breakPoint = WindowWidth().breakPoint;

  return (
    <Section background="black">
      <Grid container direction="column">
        {breakPoint === "sm" && (
          <Grid item xs={12}>
            <SpudIconMobile>
              <img
                src="/assets/img/logo/logo_potato.svg"
                alt="Royal spuds potato"
              />
            </SpudIconMobile>
          </Grid>
        )}
        <Grid
          item
          mb={2}
          sx={{
            borderBottom: "2px solid",
            borderColor: (theme) => theme.palette.primary.contrastText,
            paddingBottom: 2,
            position: "relative",
          }}
        >
          <Socials size="24" />
          {breakPoint !== "sm" && (
            <SpudIconDeskTop>
              <img
                src="/assets/img/logo/logo_potato.svg"
                alt="Royal spuds potato"
              />
            </SpudIconDeskTop>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={["sm", "md"].includes(breakPoint) ? "body2" : "body1"}
            background="black"
          >
            Copyright © 2023 - The Royal Spuds - Webdesign by Dave Schrijvers -
            All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </Section>
  );
}
