import React from "react";
import { Typography, Tabs, Tab } from "components/Layout";
import theme from "theme";

export default function HeaderFilter({
  tabs = [],
  selected = 1,
  setSelected = () => {},
  background = "black",
  centered,
  ...props
}) {
  return (
    <Tabs
      centered={centered}
      value={selected}
      TabIndicatorProps={{
        style: {
          background: theme.palette.text[background].primary,
        },
      }}
      sx={{
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.text[background].primary,
        },
      }}
    >
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={index}
            value={index}
            label={tab}
            onClick={() => setSelected(index)}
            sx={{
              color: theme.palette.text[background].primary,
              fontSize: 20,
            }}
          />
        );
      })}
    </Tabs>
  );
}
