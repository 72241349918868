import React from "react";
import MuiLink from "@mui/material/Link";
import theme from "theme";
import { Link as RouterLink } from "react-router-dom";

export default function Link({ children, href, to, background, ...props }) {
  if (to) {
    return (
      <RouterLink
        to={to}
        style={{
          color: theme.palette.colors.orange.main,
        }}
        {...props}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <MuiLink
      sx={{
        color: theme.palette.colors.orange.main,
        "& img": {
          width: "100%",
          maxWidth: "100px",
          margin: "auto",
          display: "block",
        },
      }}
      underline="hover"
      target={"_blank"}
      rel={"noopener"}
      href={href}
      {...props}
    >
      {children}
    </MuiLink>
  );
}
