import React from "react";
import { styled } from "@mui/system";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "components/Layout";
import { WindowWidth } from "utils";

const BackToTopButton = styled(Button)(() => ({
  position: "fixed",
  bottom: "20px",
  right: "20px",
  transition: "all .2s ease",
  background: "#222222CC",
  textAlign: "center",
  zIndex: 10000,
}));

export default function BackToTop() {
  let scrollTop = WindowWidth().scrollTop;

  if (scrollTop < 200) {
    return null;
  }

  return (
    <BackToTopButton
      color="primary"
      size="small"
      onClick={() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }}
    >
      <KeyboardArrowUpIcon sx={{ marginTop: 1.5 }} />
    </BackToTopButton>
  );
}
